@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Roboto:wght@300;500;900&display=swap);
@import "~normalize.css/normalize.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0 !important;
  padding: 0;
  background-color: #fefefe;
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
  background-color: #eee;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Fjalla One, sans-serif;
}

.block {
  display: block;
}
.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.loading-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.arrowLeft {
  position: absolute;
  top: 45%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
}

.arrowRight {
  position: absolute;
  top: 45%;
  width: 27px;
  right: 10px;
  height: 44px;
  margin-top: 22px;
  z-index: 10;
  cursor: pointer;
}

.row {
  margin: 0;
  justify-content: center;
  width: 100%;
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #fff !important;
  background-color: #222 !important;
  border-color: #ced4da !important;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #222;
  font-weight: 900;
  font-size: 1rem;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}

.formSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.formSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #222;
  cursor: pointer;
}

.formSlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #222;
  cursor: pointer;
}

.smartbanner-top {
  position: relative !important;
}
